import { axios } from '@/utils/request'
const basic = '/manage/sms'

const api = {
  excel: basic + '/download/excel',
  sendSMS: basic + '/sendSMS'
}
export function excel(parameter) {
  return axios({
    url: api.excel,
    method: 'get',
    params: parameter,
    responseType: 'blob',
    timeout: 0
  })
}
export function sendSMS(parameter) {
  return axios({
    url: api.sendSMS,
    method: 'post',
    data: parameter.file,
    timeout: 0
    // params: { smsContent: parameter.smsContent }
  })
}
