<template>
  <a-card :bordered="false">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{span:4}"
      :wrapper-col="{span:20}">
      <a-form-model-item label="模板下载">
        <a-button @click="downTemplate" :loading="downLoad">示例文件</a-button>
      </a-form-model-item>
      <a-form-model-item label="选择文件" prop="file">
        <a-upload :file-list="fileList" :before-upload="beforeUpload" accept=".xls,.xlsx" v-model="form.file">
          <a-button>选择文件</a-button>
        </a-upload>
      </a-form-model-item>
<!--      <a-form-model-item label="短信内容" prop="smsContent">-->
<!--        <a-textarea-->
<!--          ref="smsContent"-->
<!--          id="smsContent"-->
<!--          v-model="form.smsContent"-->
<!--          placeholder="请输入短信内容"-->
<!--          :auto-size="{ minRows: 3, maxRows: 5 }"-->
<!--        />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="插入内容">-->
<!--        <a-select default-value="#content#" style="width: 120px" v-model="selectValue">-->
<!--          <a-select-option value="#content#">-->
<!--            电话号码-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--        <a-button @click="add" style="margin-left: 30px">插入</a-button>-->
<!--      </a-form-model-item>-->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="submitForm('ruleForm')" :loading="uploading">
          确认发送
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import { excel, sendSMS } from '@/api/outbound/message'
import fileDownload from 'js-file-download'

export default {
  name: 'MessageView',
  data () {
    return {
      form: {
        file: '',
        smsContent: ''
      },
      selectValue: '#content#',
      rules: {
        file: [{ required: true, message: '请选择文件', trigger: 'change' }],
        smsContent: [{ required: true, message: '请填写短信内容', trigger: 'change' }]
      },
      fileList: [],
      uploading: false,
      downLoad: false
    }
  },
  methods: {
    downTemplate () {
      this.downLoad = true
      excel().then(res => {
        fileDownload(res, `模板.xlsx`)
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.downLoad = false
      })
    },
    add () {
      const start = document.getElementById('smsContent').selectionStart
      this.form.smsContent = this.form.smsContent.slice(0, start) + this.selectValue + this.form.smsContent.slice(start)
    },
    beforeUpload (res) {
      this.fileList = [res]
      this.form.file = res
      return false
    },
    submitForm () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const { fileList } = this
          const formData = new FormData()
          formData.append('file', fileList[0])
          this.uploading = true
          // const smsContent = this.form.smsContent
          sendSMS({ file: formData }).then((res) => {
            if (res.status !== 200) {
              throw new Error(res.body)
            }
            this.$notification.success({
              message: '提示',
              description: res.message
            })
          }).catch((e) => {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
          }).finally(() => {
            this.uploading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
